// import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa';

import { FaAngleLeft, FaChevronLeft, FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import React, { Component, useEffect, useState } from 'react';

import { FiCheck } from 'react-icons/fi';
import { FiChevronUp } from 'react-icons/fi';
import Footer from '../component/footer/Footer';
import Header from '../component/header/Header';
import ModalVideo from 'react-modal-video';
import PageHelmet from '../component/common/Helmet';
import { PortfolioList } from '../home/Interior';
import ScrollToTop from 'react-scroll-up';

const SocialShare = [
    { Social: <FaFacebookF />, link: 'https://www.facebook.com/' },
    { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/' },
    { Social: <FaInstagram />, link: 'https://www.instagram.com/' },
    { Social: <FaTwitter />, link: 'https://twitter.com/' },
];

class PortfolioDetails extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
            detail: null,
        };
        this.openModal = this.openModal.bind(this);
    }
    componentDidMount() {
        const queryParams = new URLSearchParams(this.props.location.search);
        const slug = queryParams.get('slug');
        if (!slug) {
            // slug가 없으면 이전 페이지로 이동
            this.props.history.goBack();
            return;
        }

        // PortfolioList는 상위 범위에 정의되어 있다고 가정
        const item = PortfolioList.find(item => item.slug === slug);
        if (item) {
            this.setState({ detail: item });
        } else {
            // slug가 유효하지 않으면 이전 페이지로 이동
            alert('존재하지 않은 포트폴리오입니다.');
            this.props.history.goBack();
        }
    }
    openModal() {
        this.setState({ isOpen: true });
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle="Portfolio Details" />

                <Header headertransparent="header--transparent" colorblack="color--black" />
                <header className="header-area formobile-menu header--fixed default-color one-page-navigation">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="backButton">
                                <a href="/portfolio">
                                    <FaChevronLeft /> 뒤로가기
                                </a>
                            </div>
                        </div>
                    </div>
                </header>
                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="row row--35 mb--50">
                                        <div className="col-lg-6">
                                            <div className="inner">
                                                <div className="section-title">
                                                    <h2 className="title">포트폴리오 소개</h2>
                                                    <p className="description mt--30 font-weight-bold">
                                                        {this.state?.detail?.title}
                                                    </p>
                                                    <div className="description" style={{ marginBottom: '30px' }}>
                                                        {this?.state?.detail?.descriptionDetail?.map((item, index) => {
                                                            return <p>{item}</p>;
                                                        })}
                                                    </div>

                                                    {/* <p className="description">
                                                        {this.state?.detail?.descriptionDetail}
                                                    </p> */}
                                                </div>
                                                <div className="portfolio-view-list d-flex flex-wrap">
                                                    <div className="port-view">
                                                        <span>참여률</span>
                                                        <h4>{this.state?.detail?.participationRate}</h4>
                                                    </div>

                                                    <div className="port-view">
                                                        <span>참여인원</span>
                                                        <h4>{this.state?.detail?.participants}</h4>
                                                    </div>

                                                    <div className="port-view">
                                                        <span>참여범위</span>
                                                        <h4>{this.state?.detail?.scope}</h4>
                                                    </div>
                                                </div>
                                                {this?.state?.detail?.link && (
                                                    <div className="portfolio-details-btn mt--30">
                                                        <a
                                                            className="btn-default btn-border"
                                                            target="_blank"
                                                            href={this?.state?.detail?.link}
                                                        >
                                                            사이트 접속
                                                        </a>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="portfolio-details-accordion mt_md--40 mt_sm--40">
                                                <div className="inner">
                                                    <div className="section-title">
                                                        <h4 className="title">기술스택</h4>
                                                        <div className="mt--30">
                                                            {this?.state?.detail?.techStackDetail?.map(
                                                                (item, index) => {
                                                                    return (
                                                                        <p className="description" key={index}>
                                                                            {item}
                                                                        </p>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                        <ul className="list-style--1">
                                                            {this?.state?.detail?.techStacks?.map((item, index) => {
                                                                return (
                                                                    <li key={index}>
                                                                        <FiCheck /> {item}
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="portfolio-thumb-inner">
                                        {this?.state?.detail?.detail?.map((item, index) => {
                                            return (
                                                <div className="thumb mb--50" key={index}>
                                                    {item}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}
                <Footer />
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                {/* <Footer /> */}
            </React.Fragment>
        );
    }
}
export default PortfolioDetails;
