import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

import React from 'react';

export default function Pagination({ selectPage, setSelectPage, totalPages }) {
    return (
        <div className="rn-pagination text-center">
            <ul className="page-list">
                {/* {selectPage > 1 && (
                    <li>
                        <a onClick={() => setSelectPage(selectPage - 1)}>
                            <FaAngleLeft />
                        </a>
                    </li>
                )} */}
                {[...Array(totalPages)].map((_, index) => (
                    <li key={index} className={selectPage === index + 1 ? 'active' : ''}>
                        <a onClick={() => setSelectPage(index + 1)}>{index + 1}</a>
                    </li>
                ))}
                {/* {selectPage < totalPages && (
                    <li>
                        <a onClick={() => setSelectPage(selectPage + 1)}>
                            <FaAngleRight />
                        </a>
                    </li>
                )} */}
            </ul>
        </div>
    );
}
