import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import React, { Component } from 'react';

import { Link } from 'react-router-dom';

class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <footer className="footer-area footer-style-01 bg_color--6">
                    {/* Start Call to Action Area  */}
                    <div className="im-call-to-action-area ptb--70 im-separator">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-8 col-xl-7 col-md-12 col-sm-12 col-12">
                                    <div className="inner">
                                        <h2 className="text-white mb--0">
                                            열정적으로 코딩하고 제대로 쉴 줄 아는 개발자!
                                        </h2>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-xl-3 offset-xl-2 col-md-12 col-sm-12 col-12">
                                    <div className="call-to-cation-tbn text-left text-lg-right mt_md--20 mt_sm--20">
                                        <a
                                            className="btn-default btn-large btn-border btn-opacity"
                                            href="https://www.instagram.com/josephpark0521?igsh=NjNpcm10ZzI4aTNj&utm_source=qr"
                                            target="_blank"
                                            style={{ paddingLeft: '40px', paddingRight: '40px' }}
                                        >
                                            박요셉의 일상 이야기 <FaInstagram />
                                        </a>{' '}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Call to Action Area  */}

                    {/* Start Footer Area  */}
                    <div className="footer-wrapper ptb--70">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
                                    <div className="footer-link">
                                        <h4>연락처</h4>
                                        <ul className="ft-link">
                                            <li>
                                                Email:{' '}
                                                <a href="mailto:luggporter45@gmail.com">luggporter45@gmail.com</a>
                                            </li>
                                            <li>Phone: 01054750489</li>
                                        </ul>

                                        {/* <div className="social-share-inner mt--20">
                                            <ul className="social-share social-style--2 d-flex justify-content-start liststyle">
                                                {SocialShare.map((val, i) => (
                                                    <li key={i}>
                                                        <a href={`${val.link}`}>{val.Social}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div> */}
                                    </div>
                                </div>
                                {/* End Single Widget  */}
                            </div>
                        </div>
                    </div>
                    {/* End Footer Area  */}
                </footer>
            </React.Fragment>
        );
    }
}
export default Footer;
