import { FiChevronUp, FiMenu, FiX } from 'react-icons/fi';
import React, { Fragment, useEffect, useState } from 'react';

import Footer from '../component/footer/Footer';
import Helmet from '../component/common/Helmet';
import { Link } from 'react-router-dom';
import Pagination from '../elements/common/Pagination';
import ReactPlayer from 'react-player';
import ScrollToTop from 'react-scroll-up';
import Scrollspy from 'react-scrollspy';
import ServiceList from '../elements/service/ServiceList';
import avatar from '../assets/images/portfolio/interior/avatar.png';
import portfolio1 from '../assets/images/portfolio/interior/portfolio-1.png';
import portfolio10 from '../assets/images/portfolio/interior/portfolio-10.png';
import portfolio10_1 from '../assets/images/portfolio/interior/portfolio10/portfolio-10_1.png';
import portfolio10_2 from '../assets/images/portfolio/interior/portfolio10/portfolio-10_2.png';
import portfolio10_3 from '../assets/images/portfolio/interior/portfolio10/portfolio-10_3.png';
import portfolio11 from '../assets/images/portfolio/interior/portfolio-11.png';
import portfolio11_1 from '../assets/images/portfolio/interior/portfolio11/portfolio-11_1.png';
import portfolio11_2 from '../assets/images/portfolio/interior/portfolio11/portfolio-11_2.png';
import portfolio11_3 from '../assets/images/portfolio/interior/portfolio11/portfolio-11_3.png';
import portfolio12 from '../assets/images/portfolio/interior/portfolio-12.png';
import portfolio1_1 from '../assets/images/portfolio/interior/portfolio1/portfolio-1_1.png';
import portfolio1_2 from '../assets/images/portfolio/interior/portfolio1/portfolio-1_2.png';
import portfolio1_3 from '../assets/images/portfolio/interior/portfolio1/portfolio-1_3.png';
import portfolio1_4 from '../assets/images/portfolio/interior/portfolio1/portfolio-1_4.png';
import portfolio1_5 from '../assets/images/portfolio/interior/portfolio1/portfolio-1_5.png';
import portfolio1_6 from '../assets/images/portfolio/interior/portfolio1/portfolio-1_6.png';
import portfolio1_7 from '../assets/images/portfolio/interior/portfolio1/portfolio-1_7.png';
import portfolio2 from '../assets/images/portfolio/interior/portfolio-2.png';
import portfolio2_1 from '../assets/images/portfolio/interior/portfolio2/portfolio-2_1.png';
import portfolio2_2 from '../assets/images/portfolio/interior/portfolio2/portfolio-2_2.png';
import portfolio2_3 from '../assets/images/portfolio/interior/portfolio2/portfolio-2_3.png';
import portfolio2_4 from '../assets/images/portfolio/interior/portfolio2/portfolio-2_4.png';
import portfolio2_5 from '../assets/images/portfolio/interior/portfolio2/portfolio-2_5.png';
import portfolio2_6 from '../assets/images/portfolio/interior/portfolio2/portfolio-2_6.png';
import portfolio2_7 from '../assets/images/portfolio/interior/portfolio2/portfolio-2_7.png';
import portfolio3 from '../assets/images/portfolio/interior/portfolio-3.png';
import portfolio3_1 from '../assets/images/portfolio/interior/portfolio3/portfolio-3_1.png';
import portfolio3_2 from '../assets/images/portfolio/interior/portfolio3/portfolio-3_2.png';
import portfolio3_3 from '../assets/images/portfolio/interior/portfolio3/portfolio-3_3.png';
import portfolio3_4 from '../assets/images/portfolio/interior/portfolio3/portfolio-3_4.png';
import portfolio3_5 from '../assets/images/portfolio/interior/portfolio3/portfolio-3_5.png';
import portfolio3_6 from '../assets/images/portfolio/interior/portfolio3/portfolio-3_6.png';
import portfolio3_7 from '../assets/images/portfolio/interior/portfolio3/portfolio-3_7.png';
import portfolio4 from '../assets/images/portfolio/interior/portfolio-4.png';
import portfolio4_1 from '../assets/images/portfolio/interior/portfolio4/portfolio-4_1.png';
import portfolio4_2 from '../assets/images/portfolio/interior/portfolio4/portfolio-4_2.png';
import portfolio4_3 from '../assets/images/portfolio/interior/portfolio4/portfolio-4_3.png';
import portfolio4_4 from '../assets/images/portfolio/interior/portfolio4/portfolio-4_4.png';
import portfolio4_5 from '../assets/images/portfolio/interior/portfolio4/portfolio-4_5.png';
import portfolio4_6 from '../assets/images/portfolio/interior/portfolio4/portfolio-4_6.png';
import portfolio4_7 from '../assets/images/portfolio/interior/portfolio4/portfolio-4_7.png';
import portfolio5 from '../assets/images/portfolio/interior/portfolio-5.png';
import portfolio6 from '../assets/images/portfolio/interior/portfolio-6.png';
import portfolio6_1 from '../assets/images/portfolio/interior/portfolio6/portfolio-6_1.png';
import portfolio6_2 from '../assets/images/portfolio/interior/portfolio6/portfolio-6_2.png';
import portfolio6_3 from '../assets/images/portfolio/interior/portfolio6/portfolio-6_3.png';
import portfolio6_4 from '../assets/images/portfolio/interior/portfolio6/portfolio-6_4.png';
import portfolio7 from '../assets/images/portfolio/interior/portfolio-7.png';
import portfolio7_1 from '../assets/images/portfolio/interior/portfolio7/portfolio-7_1.png';
import portfolio7_2 from '../assets/images/portfolio/interior/portfolio7/portfolio-7_2.png';
import portfolio7_3 from '../assets/images/portfolio/interior/portfolio7/portfolio-7_3.png';
import portfolio8 from '../assets/images/portfolio/interior/portfolio-8.png';
import portfolio8_1 from '../assets/images/portfolio/interior/portfolio8/portfolio-8_1.png';
import portfolio8_2 from '../assets/images/portfolio/interior/portfolio8/portfolio-8_2.png';
import portfolio9 from '../assets/images/portfolio/interior/portfolio-9.png';

const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: '박요셉 포트폴리오',
        description: '안녕하세요. 7년차 개발자 박요셉입니다.',
        buttonText: '현업 포트폴리오 보기',
        buttonLink: '/contact',
    },
];
const Portfolio_image = <img src={portfolio1} alt="React Creative Agency" />;
const Portfolio_image2 = <img src={portfolio2} alt="React Creative Agency" />;
const Portfolio_image3 = <img src={portfolio3} alt="React Creative Agency" />;
const Portfolio_image4 = <img src={portfolio4} alt="React Creative Agency" />;
const Portfolio_image5 = <img src={portfolio5} alt="React Creative Agency" />;
const Portfolio_image6 = <img src={portfolio6} alt="React Creative Agency" />;
const Portfolio_image8 = <img src={portfolio8} alt="React Creative Agency" />;
const Portfolio_image7 = <img src={portfolio7} alt="React Creative Agency" />;
const Portfolio_image9 = <img src={portfolio9} alt="React Creative Agency" />;
const Portfolio_image10 = <img src={portfolio10} alt="React Creative Agency" />;
const Portfolio_image11 = <img src={portfolio11} alt="React Creative Agency" />;
const Portfolio_image12 = <img src={portfolio12} alt="React Creative Agency" />;
const Portfolio_image1_1 = <img src={portfolio1_1} alt="React Creative Agency" />;
const Portfolio_image1_2 = <img src={portfolio1_2} alt="React Creative Agency" />;
const Portfolio_image1_3 = <img src={portfolio1_3} alt="React Creative Agency" />;
const Portfolio_image1_4 = <img src={portfolio1_4} alt="React Creative Agency" />;
const Portfolio_image1_5 = <img src={portfolio1_5} alt="React Creative Agency" />;
const Portfolio_image1_6 = <img src={portfolio1_6} alt="React Creative Agency" />;
const Portfolio_image1_7 = <img src={portfolio1_7} alt="React Creative Agency" />;
const Portfolio_image4_1 = <img src={portfolio4_1} alt="React Creative Agency" />;
const Portfolio_image4_2 = <img src={portfolio4_2} alt="React Creative Agency" />;
const Portfolio_image4_3 = <img src={portfolio4_3} alt="React Creative Agency" />;
const Portfolio_image4_4 = <img src={portfolio4_4} alt="React Creative Agency" />;
const Portfolio_image4_5 = <img src={portfolio4_5} alt="React Creative Agency" />;
const Portfolio_image4_6 = <img src={portfolio4_6} alt="React Creative Agency" />;
const Portfolio_image4_7 = <img src={portfolio4_7} alt="React Creative Agency" />;
const Portfolio_image2_1 = <img src={portfolio2_1} alt="React Creative Agency" />;
const Portfolio_image2_2 = <img src={portfolio2_2} alt="React Creative Agency" />;
const Portfolio_image2_3 = <img src={portfolio2_3} alt="React Creative Agency" />;
const Portfolio_image2_4 = <img src={portfolio2_4} alt="React Creative Agency" />;
const Portfolio_image2_5 = <img src={portfolio2_5} alt="React Creative Agency" />;
const Portfolio_image2_6 = <img src={portfolio2_6} alt="React Creative Agency" />;
const Portfolio_image2_7 = <img src={portfolio2_7} alt="React Creative Agency" />;
const Portfolio_image6_1 = <img src={portfolio6_1} alt="React Creative Agency" />;
const Portfolio_image6_2 = <img src={portfolio6_2} alt="React Creative Agency" />;
const Portfolio_image6_3 = <img src={portfolio6_3} alt="React Creative Agency" />;
const Portfolio_image6_4 = <img src={portfolio6_4} alt="React Creative Agency" />;
const Portfolio_image7_1 = <img src={portfolio7_1} alt="React Creative Agency" />;
const Portfolio_image7_2 = <img src={portfolio7_2} alt="React Creative Agency" />;
const Portfolio_image7_3 = <img src={portfolio7_3} alt="React Creative Agency" />;
const Portfolio_image8_1 = <img src={portfolio8_1} alt="React Creative Agency" />;
const Portfolio_image8_2 = <img src={portfolio8_2} alt="React Creative Agency" />;
const Portfolio_image10_1 = <img src={portfolio10_1} alt="React Creative Agency" />;
const Portfolio_image10_2 = <img src={portfolio10_2} alt="React Creative Agency" />;
const Portfolio_image10_3 = <img src={portfolio10_3} alt="React Creative Agency" />;
const Portfolio_image11_1 = <img src={portfolio11_1} alt="React Creative Agency" />;
const Portfolio_image11_2 = <img src={portfolio11_2} alt="React Creative Agency" />;
const Portfolio_image11_3 = <img src={portfolio11_3} alt="React Creative Agency" />;
const Portfolio_image3_1 = <img src={portfolio3_1} alt="React Creative Agency" />;
const Portfolio_image3_2 = <img src={portfolio3_2} alt="React Creative Agency" />;
const Portfolio_image3_3 = <img src={portfolio3_3} alt="React Creative Agency" />;
const Portfolio_image3_4 = <img src={portfolio3_4} alt="React Creative Agency" />;
const Portfolio_image3_5 = <img src={portfolio3_5} alt="React Creative Agency" />;
const Portfolio_image3_6 = <img src={portfolio3_6} alt="React Creative Agency" />;
const Portfolio_image3_7 = <img src={portfolio3_7} alt="React Creative Agency" />;
const Portfolio_image9_1 = (
    <ReactPlayer
        url="https://www.youtube.com/watch?v=mCn7MJTF39U"
        width={'100%'}
        style={{ minHeight: '500px' }}
        playing
        loop
        controls
    />
);
const Portfolio_image5_1 = (
    <video
        class="rounded-lg border border-gray-100 shadow-2xl "
        poster="/images/videos/intro-cover.webp"
        width="1024"
        height="624"
        src="https://wedesk.so/images/videos/wedesk-intro.mp4"
        loop=""
        muted=""
        autoplay=""
    ></video>
);

export const PortfolioList = [
    {
        slug: '1',
        image: Portfolio_image,
        category: 'WEB (Frontend/Backend)',
        title: 'PIECE 서비스 홈페이지 리뉴얼',
        description: '혁신 금융 플랫폼 PIECE 서비스 홈페이지 리뉴얼 버전.',
        descriptionDetail: [
            "조각 투자 플랫폼 'PIECE'의 서비스 홈페이지를 새롭게 리뉴얼했습니다. 기존에는 서비스 홍보에 중점을 두었으나, 이번 리뉴얼을 통해 투자 참여를 비롯한 다양한 기능을 추가하여 사용자 경험을 대폭 향상시켰습니다.",
            '일부 퍼블리싱 작업을 제외한 모든 프론트엔드 및 백엔드 개발을 혼자 완료했습니다.',
        ],
        participationRate: '100%',
        participants: '1명',
        scope: 'Frontend/Backend',
        techStackDetail: [
            '프론트엔드는 Next.js를 사용하여 개발하였으며, GraphQL 쿼리 언어를 통해 통신 방식을 구현했습니다.',
            '백엔드는 Node.js와 Express로 서버를 구축하였고, 데이터베이스는 MySQL을 사용하여 안정적이고 효율적인 데이터 관리를 구현했습니다.',
            '프론트엔드와 백엔드 모두 Docker를 이용해 이미지화하여 배포하였습니다.',
        ],
        techStacks: ['Nextjs, Typescript, ChakraUi, Graphql', 'Node.js, GraphQl, Express, Typeorm', 'Mysql', 'Docker'],
        detail: [
            Portfolio_image1_1,
            Portfolio_image1_2,
            Portfolio_image1_3,
            Portfolio_image1_4,
            Portfolio_image1_5,
            Portfolio_image1_6,
            Portfolio_image1_7,
        ],
        link: 'https://piece.run/',
    },
    {
        slug: '2',
        image: Portfolio_image2,
        category: 'WEB (Frontend/Backend)',
        title: '바이셀스탠다드 회사 홈페이지',
        description: '바이셀스탠다드의 채용 및 회사 정보를 제공하는 웹사이트.',
        descriptionDetail: [
            '바이셀스탠다드 회사 홈페이지는 회사의 철학과 비전을 효과적으로 전달하는 동시에, 채용 정보를 포함한 다양한 회사 관련 자료를 제공하는 홈페이지입니다.',
            '이 웹사이트는 방문자들이 바이셀스탠다드에 대해 깊이 이해할 수 있도록 돕기 위해 설계되었습니다. 회사의 역사, 회사 뉴스, 공지사항 등을 상세히 다루고 있습니다.',
            '또한, 현재 진행 중인 채용 공고와 함께, 복지 혜택 및 근무 환경에 대한 정보를 제공하여 지원자들이 회사에 대해 충분히 이해하고 지원할 수 있도록 합니다.',
        ],
        participationRate: '100%',
        participants: '1명',
        scope: 'Frontend/Backend',
        techStackDetail: [
            '초기 통신 방식은 Restful API 통신 방식으로 Web Application과 API 서버를 연결하였습니다.',
            '초기 개발 이후 고도화하는 과정에서 GraphQL로 변경하였습니다.',
            '프론트엔드는 React를 사용하여 구성하였으며, 상태 관리는 Redux를 통해 관리하였습니다.',
            '백엔드는 Node.js와 Express를 사용하여 구축하였으며, 데이터베이스는 Mysql 사용하였습니다.',
        ],
        techStacks: ['React, Typescript, ChakraUi, Graphql', 'Node.js, GraphQl, Express, Typeorm', 'Mysql', 'Docker'],
        detail: [
            Portfolio_image2_1,
            Portfolio_image2_2,
            Portfolio_image2_3,
            Portfolio_image2_4,
            Portfolio_image2_5,
            Portfolio_image2_6,
            Portfolio_image2_7,
        ],
        link: 'https://buysellstandards.com/',
    },
    {
        slug: '3',
        image: Portfolio_image3,
        category: 'WEB (Frontend/Backend)',
        title: 'PIECE 백오피스',
        description: '효율적인 관리와 운영을 위해 PIECE/회사의 백오피스 시스템을 개발.',
        descriptionDetail: [
            '청약, 상품, 정보, 매거진, 고객 관리, 직원 관리 등 웹 및 앱에 올라가는 대부분의 정보를 데이터화하여 서비스와 회사 운영을 효율적으로 관리할 수 있는 백오피스를 개발했습니다.',
            '이 백오피스는 다양한 관리 기능을 통합하여 운영의 효율성을 극대화했습니다.',
        ],
        participationRate: '100%',
        participants: '1명',
        scope: 'Frontend/Backend',
        techStackDetail: [
            '프론트엔드는 Next.js를 사용하여 개발하였으며, GraphQL 쿼리 언어를 통해 통신 방식을 구현했습니다.',
            '백엔드는 Node.js와 Express로 서버를 구축하였고, 데이터베이스는 MySQL을 사용하여 안정적이고 효율적인 데이터 관리를 구현했습니다.',
            '프론트엔드와 백엔드 모두 Docker를 이용해 이미지화하여 배포하였습니다.',
            '초기 백엔드 서버는 Spring Boot를 사용한 REST API로 개발되었으나, 입사 후 적은 개발 인원으로 효율적인 개발을 위해 Node.js와 Express로 변환 작업을 진행하였습니다.',
        ],
        techStacks: ['Nextjs, Typescript, ChakraUi, Graphql', 'Node.js, GraphQl, Express, Typeorm', 'Mysql', 'Docker'],
        detail: [
            Portfolio_image3_1,
            Portfolio_image3_2,
            Portfolio_image3_3,
            Portfolio_image3_4,
            Portfolio_image3_5,
            Portfolio_image3_6,
            Portfolio_image3_7,
        ],
        link: '',
    },
    {
        slug: '4',
        image: Portfolio_image4,
        category: 'WEB (Frontend/Backend)',
        title: 'PIECE 서비스 홈페이지 (구 버전)',
        description: '혁신 금융 플랫폼 PIECE 서비스 홈페이지 초기 버전.',
        descriptionDetail: [
            "'PIECE'는 조각 투자 플랫폼으로, 본 리뉴얼 이전 버전의 홈페이지는 서비스 관련 공지사항, 매거진, 서비스 소개, 이벤트 등의 주요 기능을 중심으로 운영되었습니다.",
            '각 기능들은 사용자들에게 중요한 정보를 제공하며, 플랫폼의 다양한 면모를 효과적으로 전달하기 위해 설계 및 개발하였습니다.',
        ],
        participationRate: '100%',
        participants: '1명',
        scope: 'Frontend/Backend',
        techStackDetail: [
            '프론트엔드는 Next.js를 사용하여 개발하였으며, GraphQL 쿼리 언어를 통해 통신 방식을 구현했습니다.',
            '백엔드는 Node.js와 Express로 서버를 구축하였고, 데이터베이스는 MySQL을 사용하여 안정적이고 효율적인 데이터 관리를 구현했습니다.',
            '프론트엔드와 백엔드 모두 Docker를 이용해 이미지화하여 배포하였습니다.',
        ],
        techStacks: ['Nextjs, Typescript, ChakraUi, Graphql', 'Node.js, GraphQl, Express, Typeorm', 'Mysql', 'Docker'],
        detail: [
            Portfolio_image4_1,
            Portfolio_image4_2,
            Portfolio_image4_3,
            Portfolio_image4_4,
            Portfolio_image4_5,
            Portfolio_image4_6,
            Portfolio_image4_7,
        ],
        link: '',
    },
    {
        slug: '5',
        image: Portfolio_image5,
        category: 'WEB/PC_APP (Frontend/Backend)',
        title: '개인화된 업무 협업 서비스 STRUM',
        description: 'Strum (업무 협업 서비스) 개발 참여.',
        descriptionDetail: [
            '개인화된 업무 협업 서비스 STRUM(현제 서비스명 WeDesk) 개발 참여',
            '맞춤형 UI로 부서별 커스텀이 가능하며, 업무의 일감 지정 및 관리가 가능한 서비스',
        ],
        participationRate: '10%',
        participants: '7명',
        scope: 'Frontend/Backend',
        techStackDetail: [],
        techStacks: ['React, Typescript, ChakraUi, Graphql', 'Node.js, GraphQl, Express, Typeorm', 'Mysql', 'Docker'],
        detail: [Portfolio_image5_1],
        link: 'https://wedesk.so/',
    },
    {
        slug: '6',
        image: Portfolio_image6,
        category: 'WEB (Frontend/Backend)',
        title: '개인 건강기록 플랫폼 inPHRCare 홈페이지',
        description: '개인 건강기록 플랫폼 inPHRCare Web 개발 참여 및 유지보수.',
        descriptionDetail: [
            'inPHR CARE는 다양한 서비스에서 수집된 개인건강기록 통합관리가 가능합니다. 사용자의 통합 건강정보 바탕의 맞춤형 컨텐츠를 제공하며, 의료진의 환자 이해도 향상을 돕는 의료기관 연동, 개인건강 기록 통합관리 플랫폼입니다.',
            '중간 개발 작업에 투입되어 20% 정도의 프론트엔드와 백엔드 개발에 참여하였습니다.',
        ],
        participationRate: '20%',
        participants: '8명',
        scope: 'Frontend/Backend',
        techStackDetail: [],
        techStacks: ['javascript, angularjs, ionic 1', 'java, spring boot', 'MariaDB'],
        detail: [Portfolio_image6_1, Portfolio_image6_2, Portfolio_image6_3, Portfolio_image6_4],
        link: 'https://inphrcare.com',
    },
    {
        slug: '7',
        image: Portfolio_image7,
        category: 'WEB (Frontend/Backend)',
        title: '경기도 광고 홍보제 접수 사이트 개발',
        description: '2023년 경기도 광고 홍보제 작품 접수 사이트 개발',
        descriptionDetail: [
            '경기도에서는 민간의 참신한 창작 홍보콘텐츠를 발굴하여 도정에 활용하기 위하여 전 국민이 참여하는 「제5회 경기도 광고홍보제 공모전」을 개최하였으며, 해당 공모전의 참가 접수를 위한 사이트.',
            '과거 경기도 광고홍보제 및 작품 접수를 하는 홈페이지를 개발하였습니다.',
        ],
        participationRate: '100%',
        participants: '1명',
        scope: 'Frontend/Backend',
        techStackDetail: [
            '프론트엔드는 React를 사용하여 구성하였으며, 상태 관리는 Redux를 통해 관리하였습니다.',
            '백엔드는 Node.js와 Express를 사용하여 구축하였으며, 데이터베이스는 Mysql 사용하였습니다.',
        ],
        techStacks: ['React, Typescript, ChakraUi, Graphql', 'Node.js, GraphQl, Express, Typeorm', 'Mysql', 'Docker'],
        detail: [Portfolio_image7_1, Portfolio_image7_2, Portfolio_image7_3],
        link: '',
    },
    {
        slug: '8',
        image: Portfolio_image8,
        category: 'APP (IONIC)',
        title: '개인 건강기록 플랫폼 inPHR 하이브리드 APP 개발',
        description: '개인 건강기록 플랫폼 inPHR 하이브리드 개발 참여 및 유지보수.',
        descriptionDetail: [
            'inPHR CARE는 다양한 서비스에서 수집된 개인건강기록 통합관리가 가능합니다. 사용자의 통합 건강정보 바탕의 맞춤형 컨텐츠를 제공하며, 의료진의 환자 이해도 향상을 돕는 의료기관 연동, 개인건강 기록 통합관리 플랫폼입니다.',
            '중간 개발 작업에 투입되어 20% 정도의 프론트엔드와 백엔드 개발에 참여하였습니다.',
        ],
        participationRate: '20%',
        participants: '8명',
        scope: 'API/APP/ADMIN',
        techStackDetail: [],
        techStacks: ['javascript, angular, ionic 4', 'java, spring boot', 'MariaDB'],
        detail: [Portfolio_image8_1, Portfolio_image8_2],
        link: 'https://www.inphr.com/service/inphrcare',
    },

    {
        slug: '9',
        image: Portfolio_image9,
        category: 'APP (IONIC)',
        title: 'inPHR IBD 하이브리드 APP 개발',
        description:
            '크론병, 궤양성 대장염 등 염증성 장질환 증상을 효과적으로 관리할 수 있는 개인 맞춤형 건강관리 앱 개발.',
        descriptionDetail: [
            '크론병, 궤양성 대장염 등 염증성 장질환 (Inflammatory Bowel Disease) 환자의 증상 및 삶의 질 개선을 위한 개인 맞춤형 건강관리 서비스입니다. 크론병, 궤양성 대장염 등 염증성 장질환 (Inflammatory Bowel Disease) 환자의 증상 및 삶의 질 개선을 위한 개인 맞춤형 건강관리 서비스입니다.',
            '30% 정도의 프론트엔드와 백엔드 개발에 참여하였습니다.',
        ],
        participationRate: '30%',
        participants: '5명',
        scope: 'API/APP/ADMIN',
        techStackDetail: [],
        techStacks: ['javascript, angular, ionic 4', 'java, spring boot', 'MariaDB'],
        detail: [Portfolio_image9_1],
        link: 'https://www.inphr.com/service/inphribd',
    },
    {
        slug: '10',
        image: Portfolio_image10,
        category: 'APP (IONIC)',
        title: 'inPHR SYM 하이브리드 APP 개발',
        description:
            '크론병, 궤양성 대장염 등 염증성 장질환 증상을 효과적으로 관리할 수 있는 개인 맞춤형 건강관리 앱 개발.',
        descriptionDetail: [
            '공황증상 예측 알고리즘으로 예방 및 관리를 해줍니다. 심리상태, 공황발생, 생활패턴 정보관리로 사용자 심리 변화를 추적하고 해당 분야 전문가 정보 및 증상관리 방법 등의 관련 정보를 제공하는 서비스입니다.',
        ],
        participationRate: '40%',
        participants: '3명',
        scope: 'API/APP/ADMIN',
        techStackDetail: [],
        techStacks: ['javascript, angular, ionic 4', 'java, spring boot', 'MariaDB'],
        detail: [Portfolio_image10_1, Portfolio_image10_2, Portfolio_image10_3],
        link: 'https://www.inphr.com/service/inphrsym',
    },
    {
        slug: '11',
        image: Portfolio_image11,
        category: 'APP (IONIC)',
        title: 'inPHR child 하이브리드 APP 개발',
        description:
            '크론병, 궤양성 대장염 등 염증성 장질환 증상을 효과적으로 관리할 수 있는 개인 맞춤형 건강관리 앱 개발.',
        descriptionDetail: [
            '식습관 및 운동량의 분석을 통해서 의료진 모니터링을 실시하며,체중입력으로 가정에서 쉽게 비만지수를 관리합니다.',
            '올바른 식습관과 운동 처방을 통한 비만개선 서비스이고 정기 내원 검사 결과 바탕의 맞춤형 목표를 제시하는 서비스입니다.',
        ],
        participationRate: '40%',
        participants: '3명',
        scope: 'API/APP/ADMIN',
        techStackDetail: [],
        techStacks: ['javascript, angular, ionic 4', 'java, spring boot', 'MariaDB'],
        detail: [Portfolio_image11_1, Portfolio_image11_2, Portfolio_image11_3],
        link: 'https://www.inphr.com/service/inphrchild',
    },
    {
        slug: '12',
        image: Portfolio_image12,
        category: 'APP (IONIC)',
        title: 'inPHRpill 의료진 대시보드 개발',
        description:
            '크론병, 궤양성 대장염 등 염증성 장질환 증상을 효과적으로 관리할 수 있는 개인 맞춤형 건강관리 앱 개발.',
        descriptionDetail: [
            'PHR 기반 환자 진료 기록과 처방 전(생산/유통), 처방 후 의약품 복용 이력 추적 가능 환자 복약 순응도 개선을 위한 의약품 통합관리 시스템 및 사용이력 관리 서비스입니다.',
        ],
        participationRate: '20%',
        participants: '6명',
        scope: 'WEB/API',
        techStackDetail: [],
        techStacks: ['typescript, react', 'java, spring boot', 'MariaDB'],
        detail: [],
        link: 'https://www.inphr.com/service/inphrpill',
    },
];

const InteriorLanding = () => {
    const [selectPage, setSelectPage] = useState(1);
    const [itemsPerPage] = useState(6);
    const [displayedList, setDisplayedList] = useState([]);

    useEffect(() => {
        const handleLoad = () => {
            console.log('All assets are loaded');
        };

        window.addEventListener('load', handleLoad);
        window.addEventListener('scroll', handleScroll);

        const elements = document.querySelectorAll('.has-droupdown > a');
        elements.forEach(element => {
            element.onclick = function () {
                this.parentElement.querySelector('.submenu').classList.toggle('active');
                this.classList.toggle('open');
            };
        });

        return () => {
            window.removeEventListener('load', handleLoad);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        var value = window.scrollY;
        if (value > 50) {
            document.querySelector('.header--fixed').classList.add('sticky');
        } else {
            document.querySelector('.header--fixed').classList.remove('sticky');
        }
    };

    const menuTrigger = () => {
        document.querySelector('.header-wrapper').classList.toggle('menu-open');
    };

    const closeMenuTrigger = () => {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    };

    useEffect(() => {
        updateDisplayedList(selectPage);
    }, [selectPage]);

    const updateDisplayedList = page => {
        const startIndex = (page - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setDisplayedList(PortfolioList.slice(startIndex, endIndex));
    };
    let title = 'About Me';

    return (
        <Fragment>
            <Helmet pageTitle="박요셉 포트폴리오" />
            {/* Start Header Area */}
            <header className="header-area formobile-menu header--fixed default-color one-page-navigation">
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo">
                            {/* <a href="/">
                                <img className="logo-1" src={logoLight} alt="Logo Images" />
                                <img className="logo-2" src={logoAllDark} alt="Logo Images" />
                            </a> */}
                        </div>
                    </div>
                    <div className="header-right">
                        <nav className="mainmenunav d-lg-block">
                            <Scrollspy
                                className="mainmenu"
                                items={['home', 'about', 'developer', 'portfolio', 'team', 'testimonial', 'portfolio']}
                                currentClassName="is-current"
                                offset={-200}
                            >
                                <li>
                                    <a href="#home">Home</a>
                                </li>
                                <li>
                                    <a href="#about">About</a>
                                </li>
                                <li>
                                    <a href="#developer">Developer</a>
                                </li>
                                <li>
                                    <a href="#portfolio">Portfolio</a>
                                </li>
                            </Scrollspy>
                        </nav>
                        {/* <div className="header-btn">
                            <a
                                className="btn-default btn-border btn-opacity"
                                target="_blank"
                                href="https://themeforest.net/checkout/from_item/31405042?license=regular"
                            >
                                <span>Contact</span>
                            </a>
                        </div> */}
                        {/* Start Humberger Menu */}
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={menuTrigger} className="menutrigger text-white">
                                <FiMenu />
                            </span>
                        </div>
                        {/* End Humberger Menu */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={closeMenuTrigger} className="closeTrigger">
                                <FiX />
                            </span>
                        </div>
                    </div>
                </div>
            </header>
            {/* End Header Area */}
            <div className="slider-wrapper" id="home">
                {/* Start Single Slide */}
                {SlideList.map((value, index) => (
                    <div
                        className="slide designer-portfolio slider-style-3  d-flex align-items-center justify-content-center bg_color--5 rn-slider-height"
                        key={index}
                    >
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-5">
                                    <div className="designer-thumbnail">
                                        <img src={avatar} alt="Slider Images" />
                                    </div>
                                </div>
                                <div className="col-lg-7 mt_md--40 mt_sm--40">
                                    <div className={`inner ${value.textPosition}`}>
                                        {value.category ? <span>{value.category}</span> : ''}
                                        <h1 className="title">
                                            박요셉 포트폴리오 <br />
                                            <span>기술 스택 및 자기소개</span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                {/* End Single Slide */}
            </div>
            {/* Start About Area */}
            <div className="about-area ptb--120  bg_color--1" id="about">
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <span className="subtitle">기술 스택</span>
                                        <h2 className="title">{title}</h2>
                                        <p className="description">
                                            안녕하세요
                                            <br />
                                            <br />
                                            저는 6년차 프론트엔드 개발자이자 스크립트 언어 개발자인 박요셉입니다. <br />
                                            최신 기술을 학습하고 활용하는 것을 좋아하며, 다양한 기술 스택을 바탕으로
                                            풀스택 개발자로서의 역량을 갖추고 있습니다. <br />
                                            저는 프로젝트의 개발, 배포, 유지보수까지 모든 과정을 주도할 수 있습니다.{' '}
                                            <br />
                                            <br />
                                            프론트엔드와 백엔드를 아우르는 통합적 시각을 통해 시스템의 성능과 사용자
                                            경험을 최적화하는 데 주력하고 있습니다. <br />
                                            최근에는 SCR 방식 전환과 GraphQL 도입을 통해 페이지 로딩 속도와 SEO 성능을
                                            크게 향상시킨 경험이 있습니다. <br />
                                            <br />
                                            이러한 경험을 통해 다양한 기술을 효과적으로 결합하여 문제를 해결하는 능력을
                                            키웠습니다. 저는 항상 최신 기술 동향을 주시하며, 이를 실제 프로젝트에 적용해
                                            나가는 과정에서 큰 보람을 느낍니다. 앞으로도 끊임없이 성장하며, 더욱
                                            혁신적인 솔루션을 제공하는 개발자가 되고자 합니다. <br />
                                            <br />
                                            감사합니다.
                                            <br />
                                            <br />
                                        </p>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h5 className="title">Frontend Skills</h5>
                                                <p>
                                                    React, NextJS, Angular, Ionic, React Native, Javascript, Typescript,
                                                    HTML, CSS, ChakraUi, Apollo, Docker ...
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h5 className="title">Backend Skills</h5>
                                                <p>
                                                    Node.js, GraphQl, Express, Typeorm, Spring, Spring Boot, Java,
                                                    Mysql, Swagger
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h5 className="title">Development Skills</h5>
                                                <p>Amazon web services(Aws), Naver cloud platform, Firebase, Docker</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h5 className="title">Outhers</h5>
                                                <p>Git, GitHub, Bitbucket, Zeplin, Figma</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End About Area */}
            {/* Start Service Area */}
            <div className="service-area creative-service-wrapper ptb--120 bg_color--1" id="developer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <span className="subtitle">저는 이런 개발자입니다.</span>
                                <h2 className="title">MERN Stack Developer</h2>
                                <p className="description">
                                    클라이언트와 서버 양쪽에서 강력한 성능을 발휘하는 풀스택 개발자로서, React와
                                    Node.js를 사용하여 혁신적이고 사용자 친화적인 웹 애플리케이션을 개발합니다.
                                    <br />
                                    Express와 MySQL을 활용해 데이터 관리를 효율적으로 수행하며, 유지보수성과 확장성을
                                    고려한 코드를 작성합니다. <br />
                                    최신 기술 트렌드에 민감하며, 팀과의 협업을 통해 최고의 결과물을 만들어내는 것을
                                    목표로 합니다.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row creative-service mt--30">
                        <div className="col-lg-12">
                            <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Area */}

            {/* Start Service Area */}
            <div className="service-area creative-service-wrapper ptb--120 bg_color--1" id="portfolio">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <span className="subtitle">현업 포트폴리오</span>
                                <h2 className="title">Portfolios</h2>
                                <p className="description">
                                    제가 협업하여 개발한 Web, Server, App 프로젝트들의 리스트를 확인하실 수 있습니다.
                                    <br />각 프로젝트는 다양한 기술 스택을 활용하여 진행되었으며, 팀원들과의 협력을 통해
                                    완성되었습니다.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Area */}

            {/* Start Portfolio Area */}
            <div className="portfolio-area pb--120 bg_color--1">
                <div className="container">
                    <div className="row mt_dec--30">
                        {displayedList.map((value, index) => (
                            <div className="col-lg-6 col-md-6 col-12 mt--30" key={index}>
                                <div className="im_portfolio">
                                    <div className="thumbnail_inner">
                                        <div className="thumbnail">
                                            <Link to={`/portfolio-details?slug=${value?.slug}`}>{value.image}</Link>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <div className="portfolio_heading">
                                                <div className="category_list">
                                                    <Link to={`/portfolio-details?slug=${value?.slug}`}>
                                                        {value.category}
                                                    </Link>
                                                </div>
                                                <h4 className="title">
                                                    <Link to={`/portfolio-details?slug=${value?.slug}`}>
                                                        {value.title}
                                                    </Link>
                                                </h4>
                                            </div>
                                            <div className="portfolio_hover">
                                                <p>{value.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <Link
                                        className="transparent_link"
                                        to={`/portfolio-details?slug=${value?.slug}`}
                                    ></Link>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="row mt--60">
                        <div className="col-lg-12">
                            {/* Start Pagination Area */}
                            <Pagination
                                selectPage={selectPage}
                                setSelectPage={setSelectPage}
                                totalPages={Math.ceil(PortfolioList.length / itemsPerPage)}
                            />
                            {/* End Pagination Area */}
                        </div>
                    </div>
                </div>
            </div>
            {/* End Portfolio Area */}

            {/* Start Footer Style */}
            <Footer />
            {/* End Footer Style */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
        </Fragment>
    );
};

export default InteriorLanding;
