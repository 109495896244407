import { FiBook, FiCode, FiLayers, FiLayout, FiTrendingUp, FiUsers } from 'react-icons/fi';
import React, { Component } from 'react';

const ServiceList = [
    {
        icon: <FiLayers />,
        title: '다양한 기술 스택에 능통한 멀티플레이어',
        description:
            '저는 프론트엔드와 백엔드 모두에서 경험을 쌓아 왔으며, React, Node 서버, GraphQL 등 다양한 언어와 프레임워크를 자유자재로 다룰 수 있습니다. 새로운 기술도 빠르게 습득하여 프로젝트에 적용할 수 있는 능력이 있습니다.',
    },
    {
        icon: <FiCode />,
        title: '문제 해결에 능한 문제 해결사',
        description:
            '개발 과정에서 발생하는 다양한 문제들을 해결하는 데 뛰어난 역량을 가지고 있습니다. 논리적 사고와 창의적인 접근을 통해 복잡한 문제도 효율적으로 해결합니다.',
    },
    {
        icon: <FiUsers />,
        title: '팀워크와 소통을 중시하는 협력자',
        description:
            '협업 프로젝트에서의 경험이 풍부하며, 팀원들과의 원활한 소통을 통해 최고의 결과를 도출해냈습니다. 열린 마음으로 피드백을 주고받으며, 팀의 목표를 위해 항상 노력합니다.',
    },
    {
        icon: <FiBook />,
        title: '지속적인 자기 개발을 추구하는 학습자',
        description:
            '최신 기술 트렌드와 베스트 프랙티스를 꾸준히 학습하며, 새로운 지식을 습득하는 데 항상 열정적입니다. 다양한 온라인 코스와 세미나에 참여하며, 자기 개발에 투자를 아끼지 않습니다.',
    },
    {
        icon: <FiLayout />,
        title: '사용자의 관점에서 생각하는 UX/UI 전문가',
        description:
            '사용자 경험을 최우선으로 고려하여 디자인하고 개발합니다. 직관적이고 사용하기 쉬운 인터페이스를 만들기 위해 항상 노력하며, 사용자 피드백을 적극 반영합니다.',
    },
    {
        icon: <FiTrendingUp />,
        title: '성과를 중시하는 결과 지향적 개발자',
        description:
            '프로젝트의 목표와 성과를 달성하는 데 집중합니다. 효율적인 코딩과 철저한 테스트를 통해 높은 품질의 소프트웨어를 제공하며, 데드라인을 준수하는 것을 중요하게 생각합니다.',
    },
];

class ServiceThree extends Component {
    render() {
        const { column } = this.props;
        const ServiceContent = ServiceList.slice(0, this.props.item);

        return (
            <React.Fragment>
                <div className="row service-main-wrapper">
                    {ServiceContent.map((val, i) => (
                        <div className={`${column}`} key={i}>
                            <a>
                                <div className="service service__style--2 text-left bg-gray">
                                    <div className="icon">{val.icon}</div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        );
    }
}
export default ServiceThree;
