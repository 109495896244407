import React, { Component } from 'react';

import { FiChevronUp } from 'react-icons/fi';
import Footer from '../component/footer/Footer';
import ScrollToTop from 'react-scroll-up';

// import Footer from '../component/footer/FooterTwo';
// import Header from '../component/header/HeaderFour';

class error404 extends Component {
    render() {
        return (
            <>
                <div className="error-page-inner bg_color--4">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <h1 className="title theme-gradient">404!</h1>
                                    <h3 className="sub-title">찾을 수 없는 페이지 입니다 </h3>
                                    <span>
                                        아직 만들지 않은 페이지입니다. 더 많은 페이지가 생기도록 노력하겠습니다.
                                    </span>
                                    <div className="error-button">
                                        <a className="btn-default" href="/">
                                            박요셉 포트폴리오 보기
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Error  */}

                <Footer />
            </>
        );
    }
}
export default error404;
