import React, { Component } from 'react';

import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

class PageHelmet extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>박요셉 포트폴리오 </title>
                    <meta name="robots" content="noindex, follow" />
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                    <meta property="og:title" content="박요셉 포트폴리오" />
                    <meta property="og:description" content="박요셉의 개발 스킬과 협업 포트폴리오를 확인하세요." />
                    <meta property="og:image" content={`${process.env.PUBLIC_URL}/avatar.png`} />
                    <meta property="og:url" content="${process.env.PUBLIC_URL}" />
                    <meta property="og:type" content="website" />
                </Helmet>
            </React.Fragment>
        );
    }
}
PageHelmet.propTypes = {
    title: PropTypes.string,
};
export default PageHelmet;
