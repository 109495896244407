import React, { Component } from 'react';

class Header extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function () {
            console.log('All assets are loaded');
        });
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open');
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
    render() {
        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle('active');
                    this.classList.toggle('open');
                };
            }
        }

        return (
            <header className={`header-area header--fixed formobile-menu header--transparent `}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo"></div>
                    </div>
                </div>
            </header>
        );
    }
}
export default Header;
