// Create Import File
import './index.scss';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
// React Required
import React, { Component } from 'react';

import InteriorLanding from './home/Interior';
import PageScrollTop from './component/PageScrollTop';
import PortfolioDetails from './elements/PortfolioDetails';
// Dark Home Layout
// Home layout
import ReactDOM from 'react-dom/client';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';
import error404 from './elements/error404';

// Element Layout
// Blocks Layout

// Common Layout
// import Layout from "./component/common/App";

class Root extends Component {
    render() {
        return (
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio`} component={InteriorLanding} />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/portfolio-details`}
                            component={PortfolioDetails}
                        />
                        <Redirect exact from="/" to="/portfolio" />
                        <Route component={error404} />
                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
            // <BrowserRouter basename={'/'}>
            //     <PageScrollTop>
            //         <Switch>
            //             <Route exact path="/portfolio" component={InteriorLanding} />
            //             <Route exact path="/portfolio-details" component={PortfolioDetails} />
            //             {/* <Redirect exact from="/" to="/portfolio" /> */}
            //         </Switch>
            //     </PageScrollTop>
            // </BrowserRouter>
        );
    }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);
